<template>
    <div class="mcd">
        <div v-if="isLargeScreen && type === 'modal' && showDatepicker" class="mcd__backdrop" @click="handleClose" />
        <transition name="mcd__fade" :css="isLargeScreen && type !== 'modal'">
            <div
                v-show="showDatepicker"
                :id="wrapperId"
                v-closable="{
                    handler: 'handleClose',
                    exclude: ['.datepicker-trigger'],
                    disabled: type === 'inline',
                }"
                class="mcd__wrapper js-datepicker-root"
                :class="wrapperClasses"
                :style="[datepickerVariables, wrapperStyles]"
            >
                <!-- Datepicker header -->
                <DatepickerHeader
                    v-if="showHeader"
                    :aria-labels="ariaLabels"
                    :texts="texts"
                    :date-one="selectedDate1"
                    :date-two="selectedDate2"
                    :preselected-date-one="dateOne"
                    :preselected-date-two="dateTwo"
                    :has-preselected-range="hasPreselectedRange"
                    :type="type"
                    @close="handleClose"
                    @previous-month="previousMonth"
                    @next-month="nextMonth"
                />

                <div class="mcd__content-wrapper">
                    <slot name="pre-section" />

                    <!-- Datepicker months -->
                    <div class="mcd__months-wrapper">
                        <!-- Month navigation (desktop only) -->
                        <div class="mcd__change-month-button mcd__change-month-button--previous">
                            <a
                                :class="{ 'link-disabled': !isPreviousMonthEnabled }"
                                :aria-label="ariaLabels.previousMonth"
                                tabindex="0"
                                @click="previousMonth"
                                @keydown.enter="previousMonth"
                            >
                                <svg-icon name="keyboard_arrow_left" />
                            </a>
                        </div>
                        <div class="mcd__change-month-button mcd__change-month-button--next">
                            <a
                                :aria-label="ariaLabels.nextMonth"
                                tabindex="0"
                                @click="nextMonth"
                                @keydown.enter="nextMonth"
                            >
                                <svg-icon name="keyboard_arrow_right" />
                            </a>
                        </div>

                        <Month
                            v-for="(month, monthIndex) in months"
                            :key="`${monthIndex}-${month.firstDateOfMonth}`"
                            class="mcd__month"
                            :month="month"
                            :date-one="selectedDate1"
                            :date-two="selectedDate2"
                            :preselected-date-one="dateOne"
                            :preselected-date-two="dateTwo"
                            :hover-date="hoverDate"
                            :focused-date="focusedDate"
                            :aria-labels="ariaLabels"
                            :is-range-mode="isRangeMode"
                            :is-single-mode="isSingleMode"
                            :are-dates-selected="areDatesSelected"
                            :clickable="clickable"
                            :is-selecting-date-one="isSelectingDate1"
                            :disabled-dates="disabledDates"
                            :disabled-periods="disabledPeriods"
                            :rented-periods="rentedPeriods"
                            :visible-months="visibleMonths"
                            :days-short="daysShort"
                            :min-date="minDate"
                            :first-unselectable-date-after-start-date="firstUnselectableDateAfterStartDate"
                            :months-to-show="localMonthsToShow"
                            :min-booking-length="minBookingLength"
                            :date-label-format="dateLabelFormat"
                            :texts="texts"
                            :has-tooltips="hasTooltips"
                            :has-preselected-range="hasPreselectedRange"
                            @date-clicked="selectDate"
                            @date-one-selected="handleDate1Change"
                            @date-two-selected="handleDate2Change"
                            @hover-change="setHoverDate"
                            @mouseleave="resetPeriodScaling"
                            @apply="apply"
                        />

                        <!-- Legend -->
                        <div v-if="showLegend" class="legend">
                            <div class="booked mr-4">
                                <div class="square" />
                                <span>{{ $t('date_picker.booked_by_renter') }}</span>
                            </div>
                            <div class="blocked">
                                <div class="square" />
                                <span>{{ $t('date_picker.blocked_by_you') }}</span>
                            </div>
                        </div>

                        <slot name="after-section" />
                    </div>

                    <!-- Notification (mobile only) -->
                    <Notice v-if="dayNotification" type="info">
                        {{ dayNotification }}
                    </Notice>
                </div>

                <!-- Datepicker footer -->
                <DatepickerFooter
                    v-if="isRangeMode && showActionButtons"
                    :texts="texts"
                    :is-apply-disabled="applyButtonDisabled"
                    :is-clear-disabled="!selectedDate1"
                    :has-desktop-apply-btn="type !== 'popup'"
                    @apply="apply"
                    @clear="clearSelection"
                    @close="handleClose"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { mapGetters } from 'vuex';
import parseISO from 'date-fns/parseISO';
import startOfMonth from 'date-fns/startOfMonth';
import formatISO from 'date-fns/formatISO';
import isAfter from 'date-fns/isAfter';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import isValid from 'date-fns/isValid';
import compareAsc from 'date-fns/compareAsc';
import isBefore from 'date-fns/isBefore';
import isSameMonth from 'date-fns/isSameMonth';
import addWeeks from 'date-fns/addWeeks';
import subWeeks from 'date-fns/subWeeks';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import isDate from 'date-fns/isDate';
import getYear from 'date-fns/getYear';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getISOWeek from 'date-fns/getISOWeek';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import getMonth from 'date-fns/getMonth';
import getISODay from 'date-fns/getISODay';
import setYear from 'date-fns/setYear';
import setMonth from 'date-fns/setMonth';
import isSameDay from 'date-fns/isSameDay';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import DatepickerHeader from './DatepickerHeader';
import Month from './Month.vue';
import Notice from '~/components/Notice';
import DatepickerFooter from '~/components/form-elements/datepicker/DatepickerFooter';

export default {
    components: { Notice, DatepickerFooter, DatepickerHeader, Month },
    props: {
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        /* eslint-disable vue/require-default-prop */
        triggerElementId: { type: String },
        dateOne: { type: [String, Date] },
        dateTwo: { type: [String, Date] },
        minDate: { type: [String, Date] },
        /* eslint-enable vue/require-default-prop */
        mode: { type: String, default: 'range' },
        offsetY: { type: Number, default: 0 },
        offsetX: { type: Number, default: 0 },
        monthsToShow: { type: Number, default: 2 },
        mobileMonthsToShow: { type: Number, default: 4 },
        disabledDates: { type: Array, default: () => [] },
        disabledPeriods: { type: Array, default: null },
        rentedPeriods: { type: Array, default: null },
        showHeader: { type: Boolean, default: true },
        showActionButtons: { type: Boolean, default: true },
        yearsForSelect: { type: Number, default: 10 },
        highSeasonMinBookingLength: { type: Number, default: null },
        lowSeasonMinBookingLength: { type: Number, default: null },
        isInModal: { type: Boolean, default: false },
        isTest: {
            type: Boolean,
            default: () => process.env.NODE_ENV === 'test',
        },
        hasTooltips: { type: Boolean, default: false },
        hasPreselectedRange: { type: Boolean, default: false },
        preselectedRangeMarked: { type: Boolean, default: false },
        disableApply: { type: Boolean, default: false },
        hideAfterSelection: { type: Boolean, default: false },
        showLegend: { type: Boolean, default: false },
        fullScreenMobile: { type: Boolean, default: true },
        startOpen: { type: Boolean, default: false },
        clickable: { type: Boolean, default: true },
        periodsClickable: { type: Boolean, default: false },
        closeOnSelect: { type: Boolean, default: true },
        resetDatesOnClose: { type: Boolean, default: true },
        type: {
            type: String,
            default: 'popup',
            validator(value) {
                return ['popup', 'modal', 'inline'].includes(value);
            },
        },
    },
    data() {
        return {
            wrapperId: `airbnb-style-datepicker-wrapper-${this.randomString(5)}`,
            dateLabelFormat: 'eeee, MMMM d, yyyy',
            showDatepicker: false,
            colors: {
                selected: '#7396FF',
                selectedText: '#fff',
                inRange: '#B7CFFF',
                blocked: '#EC5E63',
                blockedText: '#fff',
                inBlockedRange: '#F28D90',
                inBlockedRangeText: '#0E181E',
                rented: '#7396FF',
                rentedText: '#fff',
                inRentedRange: '#B7CFFF',
                inRentedRangeText: '#0E181E',
                inRangeText: '#0E181E',
                text: '#0E181E',
                disabled: '#eeeeee',
                disabledText: '#978E96',
                hoveredInRange: '#B7CFFF',
                backgroundColor: '#fff',
            },
            ariaLabels: {
                chooseDate: date => date,
                chooseStartDate: date => `Choose ${date} as your start date.`,
                chooseEndDate: date => `Choose ${date} as your end date.`,
                selectedDate: date => `Selected. ${date}`,
                unavailableDate: date => `Not available. ${date}`,
                previousMonth: 'Move backward to switch to the previous month.',
                nextMonth: 'Move forward to switch to the next month.',
                closeDatepicker: 'Close calendar',
            },
            monthNames: [
                this.$t('date_picker.months.january'),
                this.$t('date_picker.months.february'),
                this.$t('date_picker.months.march'),
                this.$t('date_picker.months.april'),
                this.$t('date_picker.months.may'),
                this.$t('date_picker.months.june'),
                this.$t('date_picker.months.july'),
                this.$t('date_picker.months.august'),
                this.$t('date_picker.months.september'),
                this.$t('date_picker.months.october'),
                this.$t('date_picker.months.november'),
                this.$t('date_picker.months.december'),
            ],
            days: [
                this.$t('date_picker.days.monday'),
                this.$t('date_picker.days.tuesday'),
                this.$t('date_picker.days.wednesday'),
                this.$t('date_picker.days.thursday'),
                this.$t('date_picker.days.friday'),
                this.$t('date_picker.days.saturday'),
                this.$t('date_picker.days.sunday'),
            ],
            daysShort: [
                this.$t('date_picker.days_short.monday'),
                this.$t('date_picker.days_short.tuesday'),
                this.$t('date_picker.days_short.wednesday'),
                this.$t('date_picker.days_short.thursday'),
                this.$t('date_picker.days_short.friday'),
                this.$t('date_picker.days_short.saturday'),
                this.$t('date_picker.days_short.sunday'),
            ],
            keys: {
                arrowDown: 40,
                arrowUp: 38,
                arrowRight: 39,
                arrowLeft: 37,
                enter: 13,
                pgUp: 33,
                pgDn: 34,
                end: 35,
                home: 36,
                esc: 27,
            },
            startingDate: '',
            months: [],
            years: [],
            selectedDate1: '',
            selectedDate2: '',
            isSelectingDate1: true,
            hoverDate: '',
            focusedDate: '',
            dayNotification: '',
            alignRight: false,
            triggerPosition: {},
            triggerWrapperPosition: {},
            viewportWidth: undefined,
            triggerElement: undefined,
            contentWrapperElement: undefined,
            initDate1: '', // used to reset selection to initial state
            initDate2: '',
            localMonthsToShow: 1,
            maxMonthLimit: 24,
            lastScrollTop: 0,
            scrollLoadingOffsetValue: 150,
        };
    },
    computed: {
        ...mapGetters(['isLargeScreen']),
        datepickerVariables() {
            return {
                '--selected-color': this.colors.selected,
                '--selected-text-color': this.colors.selectedText,
                '--in-range-color': this.colors.inRange,
                '--in-range-text-color': this.colors.inRangeText,
                '--blocked-color': this.colors.blocked,
                '--blocked-text-color': this.colors.blockedText,
                '--in-blocked-range-color': this.colors.inBlockedRange,
                '--in-blocked-range-text-color': this.colors.inBlockedRangeText,
                '--rented-color': this.colors.rented,
                '--rented-text-color': this.colors.rentedText,
                '--in-rented-range-color': this.colors.inRentedRange,
                '--in-rented-range-text-color': this.colors.inRentedRangeText,
                '--disabled-color': this.colors.disabled,
                '--disabled-text-color': this.colors.disabledText,
                '--hovered-in-range-color': this.colors.hoveredInRange,
                '--background-color': this.colors.backgroundColor,
            };
        },
        wrapperClasses() {
            return {
                'mcd__wrapper--datepicker-open': this.showDatepicker,
                'mcd__wrapper--full-screen': this.showFullscreen,
                'mcd__wrapper--modal-type': this.type === 'modal',
                'mcd__wrapper--inline-type': this.type === 'inline',
                'mcd__wrapper--periods-enabled': this.periodsClickable,
                'mcd__wrapper--with-range':
                    this.isRangeMode &&
                    (this.areDatesSelected || isAfter(parseISO(this.hoverDate), parseISO(this.selectedDate1))),
            };
        },
        wrapperStyles() {
            if (this.showFullscreen || !this.triggerPosition) {
                return;
            }

            return {
                top: `${this.triggerPosition.height + this.offsetY + 4}px`,
                left: !this.alignRight
                    ? `${this.triggerPosition.left - this.triggerWrapperPosition.left + this.offsetX}px`
                    : '',
                right: this.alignRight
                    ? `${this.triggerWrapperPosition.right - this.triggerPosition.right + this.offsetX}px`
                    : '',
            };
        },
        texts() {
            const texts = {
                apply: this.$t('date_picker.apply'),
                weekShort: this.$t('date_picker.week_short'),
                mobileHeader: this.$t('date_picker.mobile_heading'),
                desktopHeader: this.$t('date_picker.desktop_heading'),
            };

            if (this.type === 'inline') {
                texts.close = this.$t('date_picker.close_datepicker');
            } else {
                texts.clear = this.$t('date_picker.clear');
            }

            return texts;
        },
        showFullscreen() {
            return this.fullScreenMobile && !this.isLargeScreen;
        },
        areDatesSelected() {
            return !!(
                this.selectedDate1 &&
                this.selectedDate1 !== '' &&
                this.selectedDate2 &&
                this.selectedDate2 !== ''
            );
        },
        hasMinDate() {
            return !!(this.minDate && this.minDate !== '');
        },
        isRangeMode() {
            return this.mode === 'range';
        },
        isSingleMode() {
            return this.mode === 'single';
        },
        visibleMonths() {
            const numberOfMonthsArray = [];

            for (let i = 0; i < this.localMonthsToShow; i++) {
                numberOfMonthsArray.push(i);
            }

            return numberOfMonthsArray.map((_, index) => this.months[index].firstDateOfMonth);
        },
        firstUnselectableDateAfterStartDate() {
            const dateOneParsed = parseISO(this.selectedDate1);

            const firstDisabled = this.disabledDates?.find(disabledDate => {
                return isAfter(parseISO(disabledDate), dateOneParsed) && disabledDate;
            });

            if (firstDisabled) {
                return firstDisabled;
            }

            const firstPeriodDate = this.allPeriods.find(p => {
                return isAfter(parseISO(p.fromDate), dateOneParsed);
            });

            return firstPeriodDate?.fromDate;
        },
        minBookingLength() {
            if (this.$utils.isDateHighSeason(this.selectedDate1)) {
                return this.highSeasonMinBookingLength;
            }

            return this.lowSeasonMinBookingLength;
        },
        minDateAndStartingDateMonthDiff() {
            const startingDate = isValid(this.startingDate) ? this.startingDate : parseISO(this.startingDate);

            return this.hasMinDate && differenceInCalendarMonths(startingDate, parseISO(this.minDate));
        },
        isPreviousMonthEnabled() {
            return this.minDateAndStartingDateMonthDiff > 0 || !this.hasMinDate;
        },
        applyButtonDisabled() {
            return !this.areDatesSelected || this.disableApply;
        },
        allPeriods() {
            const blockedPeriods = this.disabledPeriods || [];
            const rentedPeriods = this.rentedPeriods || [];
            const allPeriods = blockedPeriods.concat(rentedPeriods);

            return allPeriods.sort((a, b) => compareAsc(parseISO(a.fromDate), parseISO(b.fromDate)));
        },
    },
    created() {
        this.setupDatepicker();
    },
    mounted() {
        this.viewportWidth = `${window.innerWidth}px`;
        this.contentWrapperElement = this.isTest
            ? document.createElement('div')
            : document.querySelector(`#${this.wrapperId} .mcd__content-wrapper`);
        this.triggerElement = this.isTest
            ? document.createElement('input')
            : document.getElementById(this.triggerElementId);

        if (this.startOpen) {
            this.openDatepicker();
        }

        window.addEventListener('resize', this.generateDatepicker);

        if (this.showFullscreen && this.contentWrapperElement) {
            this.contentWrapperElement.addEventListener('scroll', this.loadMoreMonthsOnScroll);
        }

        this.$el.addEventListener('keydown', this.trapKeyboardInput);

        if (this.triggerElement) {
            this.triggerElement.addEventListener('click', this.openDatepicker);
        }

        this.$root.$on('focus-datepicker', this.openDatepicker);
    },
    destroyed() {
        window.removeEventListener('resize', this.generateDatepicker);

        if (this.showFullscreen && this.contentWrapperElement) {
            this.contentWrapperElement.removeEventListener('scroll', this.loadMoreMonthsOnScroll);
        }

        this.$el.removeEventListener('keyup', this.handleKeyboardInput);
        this.$el.removeEventListener('keydown', this.trapKeyboardInput);

        if (this.triggerElement) {
            this.triggerElement.removeEventListener('click', this.openDatepicker);
        }

        if (!this.isInModal) {
            clearAllBodyScrollLocks();
        }

        this.$emit('closed', 'auto');
        this.triggerElement?.classList.remove('datepicker-open');
    },
    methods: {
        generateDatepicker() {
            if (!this.showDatepicker) {
                return;
            }

            if (this.isLargeScreen) {
                this.localMonthsToShow = this.monthsToShow;
                this.contentWrapperElement.style.paddingTop = '';

                if (this.type === 'modal') {
                    disableBodyScroll(document.getElementById(this.wrapperId));
                } else if (!this.isInModal) {
                    clearAllBodyScrollLocks();
                }
            } else {
                this.localMonthsToShow = this.mobileMonthsToShow;

                if (this.showDatepicker && this.showFullscreen) {
                    disableBodyScroll(this.contentWrapperElement);
                }
            }

            this.positionDatepicker();
            this.setStartDates();
            this.generateYears();
            this.generateMonths();
        },
        updatePreselectedFromDate(from) {
            this.selectedDate1 = from;
            this.initDate1 = from;
            this.focusedDate = from || '';
            this.startingDate = from ? parseISO(from) : new Date();
            this.generateMonths();
        },
        updatePreselectedToDate(to) {
            this.selectedDate2 = to;
            this.initDate2 = to;
        },
        emitDate1() {
            if (this.hasPreselectedRange) {
                this.$emit('temp-date-one', this.selectedDate1);

                return;
            }

            this.$emit('date-one-selected', this.selectedDate1);
        },
        emitDate2() {
            if (this.hasPreselectedRange) {
                this.$emit('temp-date-two', this.selectedDate2);

                return;
            }

            this.$emit('date-two-selected', this.selectedDate2);
        },
        // used only for mobile
        getDayNotification(date) {
            if (this.isMinBookingDate(date)) {
                return this.$tc('date_picker.tooltip.min_range_date', this.minBookingLength, {
                    minBookingLength: this.minBookingLength,
                });
            }

            return '';
        },
        isBlockedOrRented(date) {
            return this.allPeriods.some(
                p =>
                    date === p.fromDate ||
                    date === p.toDate ||
                    (isAfter(parseISO(date), parseISO(p.fromDate)) && isBefore(parseISO(date), parseISO(p.toDate)))
            );
        },
        selectDate(dateStr) {
            this.dayNotification = this.getDayNotification(dateStr);

            if (this.periodsClickable) {
                this.$emit('click', dateStr);

                return;
            }

            if (!this.clickable) {
                return;
            }

            if (
                this.isBlockedOrRented(dateStr) ||
                this.isDisabled(dateStr) ||
                this.isUnselectable(dateStr) ||
                this.isMinBookingDate(dateStr)
            ) {
                return;
            }

            if (this.isSingleMode) {
                this.selectedDate1 = dateStr;
                this.emitDate1();
                this.closeDatepicker();

                return;
            }

            const date = parseISO(dateStr);
            const selectedDate1 = parseISO(this.selectedDate1);

            if (this.isSelectingDate1 || isBefore(date, selectedDate1)) {
                this.selectedDate1 = dateStr;
                this.isSelectingDate1 = false;
                this.selectedDate2 = '';
            } else {
                this.selectedDate2 = dateStr;
                this.isSelectingDate1 = true;

                if (isAfter(selectedDate1, date)) {
                    this.selectedDate1 = '';
                }

                if (
                    this.areDatesSelected &&
                    (this.isLargeScreen || !this.fullScreenMobile) &&
                    (this.type === 'popup' || this.hideAfterSelection || this.startOpen)
                ) {
                    this.applyOnSelect();
                }
            }

            if (this.isLargeScreen || !this.fullScreenMobile) {
                this.emitDate1();
                this.emitDate2();
            }
        },
        handleDate1Change(date) {
            this.selectedDate1 = date;
        },
        handleDate2Change(date) {
            this.selectedDate2 = date;
        },
        shouldHandleInput(event, key) {
            return event.keyCode === key && (!event.shiftKey || event.keyCode === 191) && this.showDatepicker;
        },
        trapKeyboardInput(event) {
            // prevent keys that are used as keyboard shortcuts from propagating out of this element
            // except for the enter key, which is needed to activate buttons
            const shortcutKeyCodes = Object.keys(this.keys).map(key => this.keys[key]);
            shortcutKeyCodes.splice(shortcutKeyCodes.indexOf(13), 1);
            const shouldPreventDefault = shortcutKeyCodes.includes(event.keyCode);

            if (shouldPreventDefault) {
                event.preventDefault();
            }
        },
        handleKeyboardInput(event) {
            const focusedDate = parseISO(this.focusedDate);

            if (this.shouldHandleInput(event, this.keys.esc)) {
                this.handleClose();
            } else if (this.shouldHandleInput(event, this.keys.arrowDown)) {
                const newDate = addWeeks(focusedDate, 1);
                const changeMonths = !isSameMonth(newDate, focusedDate);
                this.setFocusedDate(newDate);

                if (changeMonths) {
                    this.nextMonth();
                }
            } else if (this.shouldHandleInput(event, this.keys.arrowUp)) {
                const newDate = subWeeks(focusedDate, 1);
                const changeMonths = !isSameMonth(newDate, focusedDate);
                this.setFocusedDate(newDate);

                if (changeMonths) {
                    this.previousMonth();
                }
            } else if (this.shouldHandleInput(event, this.keys.arrowRight)) {
                const newDate = addDays(focusedDate, 1);
                const changeMonths = !isSameMonth(newDate, focusedDate);
                this.setFocusedDate(newDate);

                if (changeMonths) {
                    this.nextMonth();
                }
            } else if (this.shouldHandleInput(event, this.keys.arrowLeft)) {
                const newDate = subDays(focusedDate, 1);
                const changeMonths = !isSameMonth(newDate, focusedDate);
                this.setFocusedDate(newDate);

                if (changeMonths) {
                    this.previousMonth();
                }
            } else if (this.shouldHandleInput(event, this.keys.enter)) {
                // on enter key, only select the date if a date is currently in focus
                const { target } = event;

                if (target && target.tagName === 'TD') {
                    this.selectDate(this.focusedDate);
                }
            } else if (this.shouldHandleInput(event, this.keys.pgUp)) {
                this.setFocusedDate(subMonths(focusedDate, 1));
                this.previousMonth();
            } else if (this.shouldHandleInput(event, this.keys.pgDn)) {
                this.setFocusedDate(addMonths(focusedDate, 1));
                this.nextMonth();
            } else if (this.shouldHandleInput(event, this.keys.home)) {
                const newDate = startOfWeek(focusedDate, {
                    weekStartsOn: 1,
                });
                const changeMonths = !isSameMonth(newDate, focusedDate);
                this.setFocusedDate(newDate);

                if (changeMonths) {
                    this.previousMonth();
                }
            } else if (this.shouldHandleInput(event, this.keys.end)) {
                const newDate = endOfWeek(focusedDate, {
                    weekStartsOn: 1,
                });
                const changeMonths = !isSameMonth(newDate, focusedDate);
                this.setFocusedDate(newDate);

                if (changeMonths) {
                    this.nextMonth();
                }
            }
        },
        generateMonths() {
            this.months = [];
            let currentMonth =
                !this.startingDate || isDate(this.startingDate) ? this.startingDate : parseISO(this.startingDate);

            for (let i = 0; i < this.localMonthsToShow; i++) {
                this.months.push(this.getMonth(currentMonth));
                currentMonth = parseISO(this.addMonths(currentMonth, 1));
            }
        },
        generateYears() {
            this.years = [];
            const currentYear = getYear(parseISO(this.startingDate));
            const startYear = this.minDate ? getYear(parseISO(this.minDate)) : currentYear - this.yearsForSelect;
            const endYear = currentYear + this.yearsForSelect;

            for (let year = startYear; year <= endYear; year += 1) {
                this.years.push(year.toString());
            }
        },
        setupDatepicker() {
            if (this.options.ariaLabels) {
                this.ariaLabels = this.copyObject(this.options.ariaLabels);
            }

            if (this.options.dateLabelFormat) {
                this.dateLabelFormat = this.copyObject(this.options.dateLabelFormat);
            }

            if (this.options.colors) {
                const colors = this.copyObject(this.options.colors);
                this.colors.selected = colors.selected || this.colors.selected;
                this.colors.inRange = colors.inRange || this.colors.inRange;
                this.colors.hoveredInRange = colors.hoveredInRange || this.colors.hoveredInRange;
                this.colors.blocked = colors.blocked || this.colors.blocked;
                this.colors.inBlockedRange = colors.inBlockedRange || this.colors.inBlockedRange;
                this.colors.blockedText = colors.blockedText || this.colors.blockedText;
                this.colors.inBlockedRangeText = colors.inBlockedRangeText || this.colors.inBlockedRangeText;
                this.colors.rented = colors.rented || this.colors.rented;
                this.colors.inRentedRange = colors.inRentedRange || this.colors.inRentedRange;
                this.colors.rentedText = colors.rentedText || this.colors.rentedText;
                this.colors.inRentedRangeText = colors.inRentedRangeText || this.colors.inRentedRangeText;
                this.colors.selectedText = colors.selectedText || this.colors.selectedText;
                this.colors.text = colors.text || this.colors.text;
                this.colors.inRangeText = colors.inRangeText || this.colors.inRangeText;
                this.colors.disabled = colors.disabled || this.colors.disabled;
                this.colors.disabledText = colors.disabledText || this.colors.disabledText;
                this.colors.backgroundColor = colors.backgroundColor || this.colors.backgroundColor;
            }

            if (this.options.monthNames && this.options.monthNames.length === 12) {
                this.monthNames = this.copyObject(this.options.monthNames);
            }

            if (this.options.days && this.options.days.length === 7) {
                this.days = this.copyObject(this.options.days);
            }

            if (this.options.daysShort && this.options.daysShort.length === 7) {
                this.daysShort = this.copyObject(this.options.daysShort);
            }

            if (this.options.texts) {
                const texts = this.copyObject(this.options.texts);
                this.texts.apply = texts.apply || this.texts.apply;
                this.texts.clear = texts.clear || this.texts.clear;
                this.texts.close = texts.close || this.texts.close;
                this.texts.weekShort = texts.weekShort || this.texts.weekShort;
                this.texts.mobileHeader = texts.mobileHeader || this.texts.mobileHeader;
                this.texts.desktopHeader = texts.desktopHeader || this.texts.desktopHeader;
            }
        },
        setStartDates() {
            let startDate = this.dateOne ? parseISO(this.dateOne) : new Date();
            const minDate = parseISO(this.minDate);

            if (this.hasMinDate && isBefore(startDate, minDate)) {
                startDate = this.minDate;
            }

            this.startingDate = startDate;

            if (this.hasPreselectedRange && !this.preselectedRangeMarked) {
                this.selectedDate1 = '';
                this.selectedDate2 = '';
            } else {
                this.selectedDate1 = this.dateOne;
                this.selectedDate2 = this.dateTwo;
                this.initDate1 = this.dateOne;
                this.initDate2 = this.dateTwo;
            }

            this.focusedDate = startDate;
        },
        getMonth(date) {
            const firstDateOfMonth = formatISO(startOfMonth(date), { representation: 'date' });
            const year = getYear(date);
            const monthNumber = getMonth(date) + 1;
            const monthName = this.monthNames[monthNumber - 1];

            return {
                year,
                firstDateOfMonth,
                monthName,
                monthNumber,
                weeks: this.getWeeks(firstDateOfMonth),
            };
        },
        getWeeks(dateStr) {
            const date = parseISO(dateStr);

            const weekDayNotInMonth = { dayNumber: 0 };
            const daysInMonth = getDaysInMonth(date);
            const year = getYear(date);
            const monthNumber = getMonth(date) + 1;
            const month = String(monthNumber).padStart(2, '0');
            const firstDayInWeek = getISODay(date);

            const weeks = [];
            let week = [];

            // add empty days to get first day in correct position
            for (let s = 1; s < firstDayInWeek; s += 1) {
                week.push(weekDayNotInMonth);
            }

            for (let d = 0; d < daysInMonth; d += 1) {
                const isLastDayInMonth = d >= daysInMonth - 1;
                const dayNumber = d + 1;
                const dayNumberFull = dayNumber < 10 ? `0${dayNumber}` : dayNumber;
                week.push({
                    dayNumber,
                    dayNumberFull,
                    fullDate: `${year}-${month}-${dayNumberFull}`,
                });

                if (week.length === 7) {
                    weeks.push(week);
                    week = [];
                } else if (isLastDayInMonth) {
                    for (let i = 0; i < 7 - week.length; i += 1) {
                        week.push(weekDayNotInMonth);
                    }

                    weeks.push(week);
                    week = [];
                }
            }

            return weeks;
        },
        getWeekNumber(week) {
            const date = week.find(w => w.fullDate);

            return getISOWeek(parseISO(date.fullDate));
        },
        findPeriod(date) {
            return this.allPeriods?.find(
                p =>
                    date === p.fromDate ||
                    date === p.toDate ||
                    (isAfter(parseISO(date), parseISO(p.fromDate)) && isBefore(parseISO(date), parseISO(p.toDate)))
            );
        },
        scalePeriod(date) {
            const period = this.findPeriod(date);

            if (period) {
                const days = eachDayOfInterval({ start: parseISO(period.fromDate), end: parseISO(period.toDate) });

                days.forEach(d => {
                    const dayEl = document.querySelector(
                        `#${this.wrapperId} td[data-date="${formatISO(d, { representation: 'date' })}"]`
                    );

                    if (dayEl) {
                        dayEl.style.transform = 'scale(1.1)';
                        dayEl.querySelector('button').style.fontSize = '15px';
                    }
                });
            }
        },
        resetPeriodScaling() {
            const allDays = document.querySelectorAll(`#${this.wrapperId} .mcd__day`);
            allDays.forEach(el => {
                el.style.transform = '';

                const buttonEl = el.querySelector('button');

                if (buttonEl) {
                    buttonEl.style.fontSize = '';
                }
            });
        },
        setHoverDate(date) {
            this.hoverDate = date;

            if (this.periodsClickable) {
                this.scalePeriod(date);
            }
        },
        setFocusedDate(date) {
            const newDate = date && isDate(date) ? date : parseISO(date);
            const formattedDate = formatISO(newDate, { representation: 'date' });
            this.focusedDate = formattedDate;

            this.$nextTick(() => {
                const dateElement = document.getElementById(`date-${formattedDate}`);

                if (dateElement && !this.startOpen) {
                    dateElement.focus();
                }
            });
        },
        resetFocusedDate(setToFirst) {
            if (this.focusedDate && !this.isDateVisible(this.focusedDate)) {
                const visibleMonthIdx = setToFirst ? 0 : this.visibleMonths.length - 1;
                const targetMonth = parseISO(this.visibleMonths[visibleMonthIdx]);
                const monthIdx = getMonth(targetMonth);
                const year = getYear(targetMonth);
                const focusedDate =
                    this.focusedDate && isDate(this.focusedDate) ? this.focusedDate : parseISO(this.focusedDate);
                const newFocusedDate = setYear(setMonth(focusedDate, monthIdx), year);

                this.focusedDate = formatISO(newFocusedDate, { representation: 'date' });
            }
        },
        isUnselectable(dateStr) {
            if (!this.isSelectingDate1 || this.isSingleMode) {
                const date = parseISO(dateStr);
                const parsedDate = parseISO(this.firstUnselectableDateAfterStartDate);

                return (
                    this.firstUnselectableDateAfterStartDate &&
                    (isAfter(date, parsedDate) || isSameDay(date, parsedDate)) &&
                    !this.isDisabled(date)
                );
            }

            return false;
        },
        isBeforeMinDate(dateStr) {
            if (!this.minDate) {
                return false;
            }

            const date = parseISO(dateStr);

            return isBefore(date, parseISO(this.minDate));
        },
        isDateVisible(dateStr) {
            if (!dateStr) {
                return false;
            }

            const date = parseISO(dateStr);
            const start = subDays(parseISO(this.visibleMonths[0]), 1);
            const end = addDays(lastDayOfMonth(parseISO(this.visibleMonths[this.monthsToShow - 1])), 1);

            return isAfter(date, start) && isBefore(date, end);
        },
        isDateDisabled(date) {
            return this.disabledDates.includes(date);
        },
        isDisabled(date) {
            return this.isDateDisabled(date) || this.isBeforeMinDate(date);
        },
        isMinBookingDate(date) {
            if (this.areDatesSelected) {
                return false;
            }

            const minRangeDate = addDays(parseISO(this.selectedDate1), this.minBookingLength);

            return (
                isSameDay(parseISO(date), parseISO(this.selectedDate1)) ||
                (isAfter(parseISO(date), parseISO(this.selectedDate1)) && isBefore(parseISO(date), minRangeDate))
            );
        },
        previousMonth() {
            if (this.isPreviousMonthEnabled) {
                this.startingDate = parseISO(this.subtractMonths(this.months[0].firstDateOfMonth));

                this.months.unshift(this.getMonth(this.startingDate));
                this.months.splice(this.months.length - 1, 1);
                this.$emit('previous-month', this.visibleMonths);
                this.resetFocusedDate(false);
            }
        },
        nextMonth() {
            this.startingDate = parseISO(this.addMonths(this.months[this.months.length - 1].firstDateOfMonth));
            const newMonth = this.getMonth(this.startingDate);
            this.months.push(newMonth);
            this.months.splice(0, 1);
            this.$emit('next-month', this.visibleMonths);
            this.resetFocusedDate(true);
        },
        subtractMonths(dateStr) {
            const date = isDate(dateStr) ? dateStr : parseISO(dateStr);

            return formatISO(subMonths(date, 1), { representation: 'date' });
        },
        addMonths(dateStr) {
            const date = isDate(dateStr) ? dateStr : parseISO(dateStr);

            return formatISO(addMonths(date, 1), { representation: 'date' });
        },
        openDatepicker() {
            if (this.showDatepicker) {
                return;
            }

            this.triggerElement?.classList.add('datepicker-open');
            this.showDatepicker = true;
            this.generateDatepicker();
            this.$emit('opened');

            this.$nextTick(() => {
                this.setFocusedDate(this.focusedDate);

                if (this.showFullscreen && this.isPreviousMonthEnabled) {
                    this.contentWrapperElement.style.paddingTop = `${this.scrollLoadingOffsetValue}px`;
                    this.contentWrapperElement.scrollTop = this.scrollLoadingOffsetValue;
                } else {
                    this.contentWrapperElement.style.paddingTop = '';
                }
            });

            // to avoid selecting dates if user holds Enter key while opening the datepicker
            // (e.g. on homepage when user selects location with Enter key)
            setTimeout(() => {
                this.$el.addEventListener('keyup', this.handleKeyboardInput);
            }, 500);
        },
        clearSelection() {
            this.selectedDate1 = '';
            this.selectedDate2 = '';
            this.initDate1 = '';
            this.initDate2 = '';
            this.isSelectingDate1 = true;
            this.dayNotification = '';

            if (!this.hasPreselectedRange) {
                this.$emit('cleared');
            }
        },
        resetSelection() {
            this.selectedDate1 = this.initDate1;
            this.selectedDate2 = this.initDate2;
            this.isSelectingDate1 = true;
            this.emitDate1();
            this.emitDate2();
        },
        closeDatepicker() {
            if (this.startOpen) {
                return;
            }

            this.showDatepicker = false;

            if (!this.isInModal) {
                clearAllBodyScrollLocks();
            }

            this.$emit('closed');
            this.triggerElement?.classList.remove('datepicker-open');
            this.$el.removeEventListener('keyup', this.handleKeyboardInput);

            if (this.showFullscreen) {
                this.contentWrapperElement.scrollTop = 0;
            }
        },
        handleClose() {
            if (this.showDatepicker) {
                this.closeDatepicker();

                if (this.resetDatesOnClose) {
                    this.resetSelection();
                }
            }
        },
        apply() {
            this.$emit('apply', this.selectedDate1, this.selectedDate2);
            this.closeDatepicker();
        },
        applyOnSelect() {
            this.$emit('apply', this.selectedDate1, this.selectedDate2);

            if (this.closeOnSelect) {
                this.closeDatepicker();
            }
        },
        positionDatepicker() {
            const triggerWrapperElement = this.triggerElement?.closest('.datepicker-trigger');
            this.triggerPosition = this.triggerElement?.getBoundingClientRect();

            if (triggerWrapperElement) {
                this.triggerWrapperPosition = triggerWrapperElement.getBoundingClientRect();
            } else {
                this.triggerWrapperPosition = { left: 0, right: 0 };
            }

            const viewportWidth = document.documentElement.clientWidth || window.innerWidth;
            this.viewportWidth = `${viewportWidth}px`;

            this.$nextTick(() => {
                const datepickerWrapper = document.getElementById(this.wrapperId);

                if (!this.triggerElement || !datepickerWrapper) {
                    return;
                }

                const rightPosition = this.triggerPosition.left + datepickerWrapper.getBoundingClientRect().width;
                this.alignRight = rightPosition > viewportWidth;
            });
        },
        loadMoreMonthsOnScroll() {
            if (!this.isPreviousMonthEnabled) {
                this.contentWrapperElement.style.paddingTop = '';
            }

            if (this.localMonthsToShow >= this.maxMonthLimit) {
                this.contentWrapperElement.style.paddingTop = '';

                return;
            }

            const { scrollTop, offsetHeight, scrollHeight } = this.contentWrapperElement;

            if (scrollTop > this.lastScrollTop) {
                // loading next months (user is scrolling down)
                if (scrollTop + offsetHeight + this.scrollLoadingOffsetValue >= scrollHeight) {
                    this.localMonthsToShow += this.mobileMonthsToShow;
                    this.generateMonths();
                }
            } else if (this.isPreviousMonthEnabled && scrollTop < this.scrollLoadingOffsetValue / 2) {
                let subtractAmount = this.mobileMonthsToShow;
                const monthHeight = this.contentWrapperElement.querySelector('.mcd__month').offsetHeight;

                // Check how many months are left to load
                if (this.minDateAndStartingDateMonthDiff <= this.mobileMonthsToShow - 1) {
                    this.localMonthsToShow += this.minDateAndStartingDateMonthDiff;
                    subtractAmount = this.minDateAndStartingDateMonthDiff;
                } else {
                    this.localMonthsToShow += this.mobileMonthsToShow;
                }

                // loading previous months
                this.startingDate = subMonths(this.startingDate, subtractAmount);
                this.contentWrapperElement.scrollTop += subtractAmount * monthHeight; // compensate scrollTop because of scrolling in past
                this.generateMonths();
            }

            this.lastScrollTop = scrollTop;
        },
        copyObject(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        randomString(length) {
            let text = '';
            const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

            for (let i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }

            return text;
        },
    },
};
</script>

<style lang="scss">
@import '~/sass/_variables.scss';

.datepicker-trigger {
    position: relative;
    overflow: visible;
}

.mcd {
    * {
        box-sizing: border-box;
    }

    &__wrapper {
        white-space: nowrap;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: $default-border-radius;
        z-index: $z-datepicker-fullscreen;
        box-sizing: border-box;

        @include lg {
            padding: 24px;
            position: absolute;
            border: none;
            background-color: var(--background-color);

            &:not(.mcd__wrapper--inline-type) {
                border: 1px solid $border-color;
            }
        }
        &--modal-type {
            @include lg {
                position: fixed;
                left: 50% !important;
                top: 50% !important;
                transform: translate(-50%, -50%);
                width: fit-content;
                overflow-y: auto;
                max-height: calc(95vh - 30px);
            }
        }

        &--periods-enabled .mcd__day {
            &--blocked,
            &--in-blocked-range,
            &--rented,
            &--in-rented-range {
                button {
                    cursor: pointer;
                }
            }
        }

        &--inline-type {
            @include lg {
                position: static;
                width: fit-content;
                overflow-y: auto;
                max-height: calc(95vh - 30px);
                padding: 16px 13px;

                .mcd__content-wrapper {
                    padding: 0;
                }
            }
        }

        &--full-screen {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            border: none;
            z-index: $z-datepicker-fullscreen;

            .mcd__change-month-button {
                display: none;

                @include lg {
                    display: block;
                }
            }
        }
        // doing it like this because we have to have a selection range in order to apply styles for start and end date
        &--with-range {
            .mcd__selected-date-one,
            .mcd__selected-date-two {
                background: var(--in-range-color);
            }
            .mcd__selected-date-one {
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
            }
            .mcd__selected-date-two {
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
            }
        }
    }
    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: $z-datepicker-fullscreen;
    }
    &__change-month-button {
        position: absolute;
        top: 8px;

        &--previous {
            left: 8px;
        }
        &--next {
            right: 8px;
        }

        a {
            > svg {
                height: 32px;
                width: 32px;
                color: $primary;
            }
            &.link-disabled svg {
                color: $disabled;
            }
        }
    }
    &__content-wrapper {
        .notice {
            position: sticky;
            bottom: 5px;
            margin: 0 5px;

            @include lg {
                display: none;
            }
        }

        @media (max-width: $bp-lg-min) {
            overflow-y: auto;
        }
    }
    &__months-wrapper {
        position: relative;
        background: $white;
        border-radius: $default-border-radius;

        @include lg {
            width: max-content;
            border: 1px solid $border-color;
        }

        @media (max-width: $bp-lg-min) {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
        }
    }

    .legend {
        padding: 16px;
        display: block;
        @include font-size(16px);

        @include lg {
            display: flex;
        }

        .square {
            width: 18px;
            height: 18px;
            position: relative;
            border: 1px solid $gray-dark;
            margin-right: 32px;
            border-radius: 4px 0 0 4px;
            box-sizing: border-box;

            &:after {
                content: '';
                width: 18px;
                height: 18px;
                position: absolute;
                top: -1px;
                left: 17px;
                border: solid $gray-dark;
                border-width: 1px 1px 1px 0;
                border-radius: 0 4px 4px 0;
                box-sizing: border-box;
            }
        }

        .booked,
        .blocked {
            display: flex;
            align-items: center;
        }

        .booked {
            margin-bottom: 16px;
            .square {
                background: $blue;

                &:after {
                    background: $bright-blue;
                }
            }

            @include lg {
                margin: 0 30px 0 0;
            }
        }

        .blocked .square {
            background: $warning;

            &:after {
                background: $warning-light;
            }
        }
    }
}

// fade in
.mcd__fade-enter-active,
.mcd__fade-leave-active {
    transition: all 0.2s ease;
}

.mcd__fade-enter,
.mcd__fade-leave-active {
    opacity: 0;
}
</style>
