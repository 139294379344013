<template>
    <div>
        <div v-if="type === 'inline'" class="mcd__inline-header" @click="$emit('close')">
            <svg-icon name="keyboard_arrow_left" />
            <span>{{ $t('buttons.back') }}</span>
        </div>
        <template v-else>
            <div class="mcd__general-header">
                <h3 v-if="isLargeScreen">{{ texts.desktopHeader }}</h3>
                <h3 v-else>{{ texts.mobileHeader }}</h3>
                <div class="mcd__header-close">
                    <a :aria-label="ariaLabels.closeDatepicker" tabindex="0" @click="$emit('close')">
                        <svg-icon name="close" />
                    </a>
                </div>
            </div>

            <!-- Datepicker date selection info -->
            <div v-if="hasPreselectedRange && isLargeScreen" class="mcd__header-content">
                <p v-if="preselectedDateOne && preselectedDateTwo">
                    <span>{{ $t('date_picker.preselected_dates') }}</span>
                    <span>{{ preselectedDatesFormatted }}</span>
                </p>
                <p>
                    <span>{{ $t('date_picker.newly_selected_dates') }}</span>
                    <strong>{{ newDatesFormatted }}</strong>
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'DatepickerHeader',
    props: {
        /* eslint-disable vue/require-default-prop */
        dateOne: { type: [String, Date] },
        dateTwo: { type: [String, Date] },
        preselectedDateOne: { type: [String, Date] },
        preselectedDateTwo: { type: [String, Date] },
        /* eslint-enable vue/require-default-prop */
        hasPreselectedRange: { type: Boolean, default: false },
        ariaLabels: { type: Object, default: () => {} },
        texts: { type: Object, default: () => {} },
        type: {
            type: String,
            default: 'popup',
        },
    },
    computed: {
        ...mapGetters(['isLargeScreen']),
        preselectedDatesFormatted() {
            const { dateFormat } = this.$options.filters;

            return `${dateFormat(this.preselectedDateOne)} - ${dateFormat(this.preselectedDateTwo)}`;
        },
        newDatesFormatted() {
            const { dateFormat } = this.$options.filters;

            let dates = '';

            if (this.dateOne) {
                dates = dateFormat(this.dateOne);
            }

            if (this.dateTwo) {
                dates += ` - ${dateFormat(this.dateTwo)}`;
            }

            return dates;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~/sass/_variables.scss';

.mcd {
    &__inline-header {
        border-bottom: 1px solid $gray;
        padding: 23px 20px;

        span {
            vertical-align: middle;
        }

        .svg-icon {
            width: 24px;
            height: 24px;
            margin-right: 5px;
        }

        @include lg {
            display: none;
        }
    }

    &__general-header {
        position: relative;
        min-height: 32px;
        padding: 20px 16px;
        line-height: 32px;
        text-align: center;

        h3 {
            @include font-size(20px);
            font-weight: bold;
        }

        @include lg {
            text-align: left;
            padding: 0;
            margin-bottom: 16px;
            height: auto;
            background: none;
        }
    }

    &__header-close {
        position: absolute;
        right: 16px;
        top: 0;
        transform: translateY(50%);

        a .svg-icon {
            width: 32px;
            height: 32px;
            color: $primary;
        }

        @include lg {
            right: 0;
            transform: none;
        }
    }

    &__header-content {
        @include font-size(16px);
        text-align: left;
    }
}
</style>
