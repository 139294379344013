<template>
    <div>
        <div class="mcd__month-name">
            <span>{{ month.monthName }}</span>
            <span>{{ month.year }}</span>
        </div>

        <table class="mcd__month-table" role="presentation">
            <tbody>
                <tr>
                    <td class="mcd__week-title">
                        <button class="mcd__day-button">{{ texts.weekShort }}</button>
                    </td>
                    <td v-for="(day, dayIndex) in daysShort" :key="dayIndex" class="mcd__day-title">
                        {{ day }}
                    </td>
                </tr>
                <tr v-for="(week, index) in month.weeks" :key="index" class="mcd__week">
                    <td class="mcd__week-number">{{ getWeekNumber(week) }}</td>
                    <td
                        v-for="({ fullDate, dayNumber }, dayIndex) in week"
                        :id="`date-${fullDate}`"
                        :key="`${dayIndex}_${dayNumber}`"
                        class="mcd__day"
                        :data-date="fullDate"
                        :tabindex="isDateVisible(fullDate) && isSameDate(focusedDate, fullDate) ? 0 : -1"
                        :aria-label="isDateVisible(fullDate) ? getAriaLabelForDate(fullDate) : false"
                        :class="dayClasses(dayNumber, fullDate)"
                        @mouseover="handleHover(fullDate)"
                        @mouseleave="$emit('mouseleave')"
                    >
                        <button
                            v-if="dayNumber"
                            v-tooltip="{
                                content: dayTooltipText,
                                disabled: !(hasTooltips && isLargeScreen && dayTooltipText),
                            }"
                            class="mcd__day-button"
                            type="button"
                            tabindex="-1"
                            :date="fullDate"
                            @click="selectDate(fullDate)"
                        >
                            {{ dayNumber }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import parseISO from 'date-fns/parseISO';
import getISOWeek from 'date-fns/getISOWeek';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import format from 'date-fns/format';

export default {
    name: 'Month',
    props: {
        /* eslint-disable vue/require-default-prop */
        dateOne: { type: [String, Date] },
        dateTwo: { type: [String, Date] },
        preselectedDateOne: { type: [String, Date] },
        preselectedDateTwo: { type: [String, Date] },
        minDate: { type: [String, Date] },
        /* eslint-enable vue/require-default-prop */
        hoverDate: { type: String, required: true },
        month: { type: Object, default: () => {} },
        ariaLabels: { type: Object, default: () => {} },
        texts: { type: Object, default: () => {} },
        hasTooltips: { type: Boolean, default: false },
        hasPreselectedRange: { type: Boolean, default: false },
        isRangeMode: { type: Boolean, default: false },
        isSingleMode: { type: Boolean, default: false },
        isSelectingDateOne: { type: Boolean, default: false },
        areDatesSelected: { type: Boolean, default: false },
        clickable: { type: Boolean, default: true },
        disabledDates: { type: Array, default: () => [] },
        disabledPeriods: { type: Array, default: null },
        rentedPeriods: { type: Array, default: null },
        visibleMonths: { type: Array, default: () => [] },
        daysShort: { type: Array, default: () => [] },
        minBookingLength: { type: Number, default: null },
        monthsToShow: { type: Number, default: 2 },
        dateLabelFormat: { type: String, required: true },
        focusedDate: { type: [Date, String], required: true },
        firstUnselectableDateAfterStartDate: { type: String, default: null },
    },
    data() {
        return {
            dayTooltipText: '',
        };
    },
    computed: {
        ...mapGetters(['isLargeScreen']),
    },
    methods: {
        dayClasses(dayNumber, fullDate) {
            return {
                'mcd__day--enabled': dayNumber !== 0 && this.clickable,
                'mcd__day--empty': dayNumber === 0,
                'mcd__day--disabled': this.isDisabled(fullDate),
                'mcd__day--blocked': this.isBlocked(fullDate),
                'mcd__day--blocked-date-two': this.disabledPeriods?.some(p => p.toDate === fullDate),
                'mcd__day--in-blocked-range': this.isInBlockedRange(fullDate),
                'mcd__day--rented': this.isRented(fullDate),
                'mcd__day--in-rented-range': this.isInRentedRange(fullDate),
                'mcd__day--rented-date-two': this.rentedPeriods?.some(p => p.toDate === fullDate),
                'mcd__day--selected': fullDate && (this.dateOne === fullDate || this.dateTwo === fullDate),
                'mcd__day--in-range': this.isInRange(fullDate),
                'mcd__day--min-booking-day': this.isMinBookingDate(fullDate),
                'mcd__day--hovered': this.isHoveredInRange(fullDate),
                'mcd__day--unselectable': this.isUnselectable(fullDate),
                'mcd__day--preselected': this.isPreselected(fullDate),
                'mcd__selected-date-one': fullDate && fullDate === this.dateOne,
                'mcd__selected-date-two': fullDate && fullDate === this.dateTwo,
            };
        },
        getWeekNumber(week) {
            const date = week.find(w => w.fullDate);

            return getISOWeek(parseISO(date.fullDate));
        },
        isDateVisible(dateStr) {
            if (dateStr) {
                return true;
            }

            const date = parseISO(dateStr);
            const start = subDays(parseISO(this.visibleMonths[0]), 1);
            const end = addDays(lastDayOfMonth(parseISO(this.visibleMonths[this.monthsToShow])), 1);

            return isAfter(date, start) && isBefore(date, end);
        },
        isSameDate(date1Str, date2Str) {
            const date1 = parseISO(date1Str);
            const date2 = parseISO(date2Str);

            return isSameDay(date1, date2);
        },
        getAriaLabelForDate(dateStr) {
            const isDisabled = this.isDisabled(dateStr);
            const isSelected = this.isSelected(dateStr);
            const date = parseISO(dateStr);
            const dateLabel = format(date, this.dateLabelFormat);

            if (isDisabled) {
                return this.ariaLabels.unavailableDate(dateLabel);
            }

            if (isSelected) {
                return this.ariaLabels.selectedDate(dateLabel);
            }

            if (this.isRangeMode) {
                if (this.isSelectingDateOne) {
                    return this.ariaLabels.chooseStartDate(dateLabel);
                }

                return this.ariaLabels.chooseEndDate(dateLabel);
            }

            return this.ariaLabels.chooseDate(dateLabel);
        },
        handleHover(date) {
            this.dayTooltipText = '';

            if (date && this.isLargeScreen) {
                this.$emit('hover-change', date);
                this.dayTooltipText = this.getDayTooltipText(date);
            }
        },
        isDisabled(date) {
            return this.isDateDisabled(date) || this.isBeforeMinDate(date);
        },
        isBlocked(date) {
            return this.disabledPeriods?.some(p => p.fromDate === date || p.toDate === date);
        },
        isInBlockedRange(date) {
            return this.disabledPeriods?.some(
                p => isAfter(parseISO(date), parseISO(p.fromDate)) && isBefore(parseISO(date), parseISO(p.toDate))
            );
        },
        isRented(date) {
            return this.rentedPeriods?.some(p => p.fromDate === date || p.toDate === date);
        },
        isInRentedRange(date) {
            return this.rentedPeriods?.some(
                p => isAfter(parseISO(date), parseISO(p.fromDate)) && isBefore(parseISO(date), parseISO(p.toDate))
            );
        },
        isSelected(date) {
            if (!date) {
                return undefined;
            }

            return this.dateOne === date || this.dateTwo === date;
        },
        isInRange(dateStr) {
            if (!this.areDatesSelected || this.isSingleMode) {
                return false;
            }

            const date = parseISO(dateStr);
            const dateOne = parseISO(this.dateOne);
            const dateTwo = parseISO(this.dateTwo);
            const hoverDate = parseISO(this.hoverDate);

            const isInSelectedRange = isAfter(date, dateOne) && isBefore(date, dateTwo);

            const isInHoverRange = !this.areDatesSelected && isAfter(date, dateOne) && isBefore(date, hoverDate);

            return isInSelectedRange || isInHoverRange;
        },
        isHoveredInRange(dateStr) {
            if (this.isSingleMode || this.areDatesSelected) {
                return false;
            }

            const date = parseISO(dateStr);
            const dateOne = parseISO(this.dateOne);
            const hoverDate = parseISO(this.hoverDate);

            return isAfter(date, dateOne) && isBefore(date, hoverDate);
        },
        isMinBookingDate(date) {
            if (this.dateOne && !this.isSelectingDateOne) {
                const minRangeDate = addDays(parseISO(this.dateOne), this.minBookingLength);

                return (
                    isSameDay(parseISO(date), parseISO(this.dateOne)) ||
                    (isAfter(parseISO(date), parseISO(this.dateOne)) && isBefore(parseISO(date), minRangeDate))
                );
            }

            return false;
        },
        isDateDisabled(date) {
            return this.disabledDates.includes(date);
        },
        isBeforeMinDate(dateStr) {
            if (!this.minDate) {
                return false;
            }

            const date = parseISO(dateStr);

            return isBefore(date, parseISO(this.minDate));
        },
        isUnselectable(dateStr) {
            if (!this.isSelectingDateOne || this.isSingleMode) {
                const date = parseISO(dateStr);
                const parsedDate = parseISO(this.firstUnselectableDateAfterStartDate);

                return (
                    this.firstUnselectableDateAfterStartDate &&
                    (isAfter(date, parsedDate) || isSameDay(date, parsedDate)) &&
                    !this.isDisabled(date)
                );
            }

            return false;
        },
        isPreselected(dateStr) {
            if (!this.hasPreselectedRange) {
                return;
            }

            const date = parseISO(dateStr);
            const date1 = parseISO(this.preselectedDateOne);
            const date2 = parseISO(this.preselectedDateTwo);

            return isSameDay(date, date1) || isSameDay(date, date2) || (isAfter(date, date1) && isBefore(date, date2));
        },
        getDayTooltipText(dateStr) {
            if (this.isDateDisabled(dateStr) || this.isUnselectable(dateStr)) {
                return this.$t('date_picker.tooltip.not_available');
            }

            if (this.isMinBookingDate(dateStr)) {
                return this.$tc('date_picker.tooltip.min_range_date', this.minBookingLength, {
                    minBookingLength: this.minBookingLength,
                });
            }

            return '';
        },
        selectDate(dateStr) {
            this.$emit('date-clicked', dateStr);
        },
    },
};
</script>

<style lang="scss">
@import '~/sass/_variables.scss';

.mcd {
    &__month-name {
        text-align: center;
        margin: 0 0 16px;
        font-weight: bold;
        @include font-size(16px);
    }
    &__month-table {
        border-collapse: collapse;
        border-spacing: 0;
        background: $white;
        width: 100%;
        max-width: 100%;
    }
    &__month {
        display: inline-block;
        padding: 16px;
        min-width: 375px;

        @include lg {
            width: 50%;
        }
    }
    &__week-number,
    &__week-title {
        text-align: center;
        font-weight: 600;
        vertical-align: middle;
        pointer-events: none;
        @include font-size(14px);

        button {
            @include font-size(14px);
        }
    }
    &__week-number,
    &__week-title,
    &__day {
        width: 12.5%;
    }
    &__week-number {
        color: var(--disabled-text-color);
        border-right: 1px solid $border-color;
    }
    &__day-title {
        @include font-size(14px);
        text-align: center;
    }
    &__day {
        padding: 0;
        position: relative;
        &--selected button {
            border-radius: 50%;
            background: var(--selected-color);
            color: var(--selected-text-color);
        }
        &--in-range {
            background: var(--in-range-color);
            color: var(--in-range-text-color);
        }
        &--hovered {
            @include lg {
                background: var(--hovered-in-range-color);
                color: var(--in-range-text-color);
            }
        }
        &--min-booking-day {
            color: $gray-dark;
        }
        &--enabled {
            @include lg {
                &:hover:not(.mcd__day--selected):not(.mcd__day--in-range):not(.mcd__day--blocked):not(.mcd__day--in-blocked-range):not(.mcd__day--rented):not(.mcd__day--in-rented-range) {
                    background-color: $gray-light;
                }

                button {
                    cursor: pointer;
                }
            }
            &:focus {
                outline: none;

                @include lg {
                    button {
                        outline: auto 5px Highlight;
                        outline: auto 5px -webkit-focus-ring-color;
                    }
                }
            }
        }
        &--disabled:not(.mcd__day--selected):not(.mcd__day--in-range):not(.mcd__day--blocked):not(.mcd__day--in-blocked-range):not(.mcd__day--rented):not(.mcd__day--in-rented-range) {
            background: var(--disabled-color);
            color: var(--disabled-text-color);

            button {
                cursor: default;
                text-decoration: line-through;
            }
        }
        &--preselected:not(.mcd__day--selected):not(.mcd__day--in-range):not(.mcd__day--hovered) {
            background: var(--disabled-color);
        }
        &--blocked {
            background: var(--in-blocked-range-color);
            border-radius: 50% 0 0 50%;
            z-index: 1;
            button {
                border-radius: 50%;
                background: var(--blocked-color);
                color: var(--blocked-text-color);
            }
        }
        &--in-blocked-range {
            background: var(--in-blocked-range-color);
            color: var(--in-blocked-range-text-color);
        }
        &--blocked-date-two {
            border-radius: 0 50% 50% 0;
        }
        &--rented {
            background: var(--in-rented-range-color);
            border-radius: 50% 0 0 50%;
            z-index: 1;
            button {
                border-radius: 50%;
                background: var(--rented-color);
                color: var(--rented-text-color);
            }
        }
        &--in-rented-range {
            background: var(--in-rented-range-color);
            color: var(--in-rented-range-text-color);
        }
        &--rented-date-two {
            border-radius: 0 50% 50% 0;
        }
        &--blocked,
        &--in-blocked-range,
        &--rented,
        &--in-rented-range {
            button {
                cursor: initial;
            }
        }
        &--unselectable:not(.mcd__day--blocked):not(.mcd__day--in-blocked-range):not(.mcd__day--rented):not(.mcd__day--in-rented-range) {
            color: var(--disabled-text-color);
            background: none;

            button {
                text-decoration: line-through;
                cursor: initial;
            }
        }
    }
    &__day-button {
        background: transparent;
        width: 100%;
        height: 100%;
        border: none;
        color: inherit;
        text-align: center;
        user-select: none;
        font-weight: inherit;
        padding: 0;
        aspect-ratio: 1 / 1;
        font-family: $base-font;
        @include font-size(16px);

        .tooltip {
            width: 100%;
            height: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &__text {
                color: $primary;
            }
        }
    }
}
</style>
