<template>
    <div class="mcd-footer">
        <div class="mcd-footer__actions" :class="actionsModifierClasses">
            <a v-if="texts.clear" :class="{ 'link-disabled': isClearDisabled }" @click.prevent="$emit('clear')">
                {{ texts.clear }}
            </a>
            <a v-else-if="texts.close" @click.prevent="$emit('close')">{{ texts.close }}</a>
            <button
                class="button button--primary apply-btn"
                type="button"
                :disabled="isApplyDisabled"
                @click="$emit('apply')"
            >
                {{ texts.apply }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DatepickerFooter',
    props: {
        /* eslint-enable vue/require-default-prop */
        texts: { type: Object, default: () => {} },
        isApplyDisabled: { type: Boolean, default: false },
        isClearDisabled: { type: Boolean, default: false },
        hasDesktopApplyBtn: { type: Boolean, default: false },
    },
    computed: {
        actionsModifierClasses() {
            return {
                'mcd-footer__actions--s': !this.hasDesktopApplyBtn,
                'mcd-footer__actions--aligned-to-right': this.texts.close,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~/sass/_variables.scss';

.mcd-footer {
    padding: 16px;
    border-top: 1px solid $border-color;

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include font-size(16px);

        .apply-btn {
            margin-left: 20px;
        }

        &--s {
            @include lg {
                .apply-btn {
                    display: none;
                }
            }
        }

        &--aligned-to-right {
            justify-content: flex-end;
        }
    }

    @include lg {
        border: none;
        padding: 24px 0 0;
    }
}
</style>
