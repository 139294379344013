// Color names, copied from _colors.scss
export default {
    white: '#fff',
    primary: '#0E181E',
    blue: '#7396FF',
    'bright-blue': '#B7CFFF',
    disabled: '#978E96',
    warning: '#EC5E63',
    'warning-light': '#F28D90',
};
